<template>
  <div>
    <div v-if="showSuccessModal" class="modal" tabindex="-1" role="dialog"
      style="display: flex; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.8);">
      <div style="background-color: var(--white);color: black; max-width: 468px; width: 85%;height: 40vh;"
        class="modal-content">
        <div class="modal-body">
          <div style="border-bottom: solid black 2px;">
            <div class="row m-0 p-0">
              <div class="col-8 p-0 m-0">
                <div class="d-flex">
                  <span style="background-color: var(--darkBackground);"
                    class="btn btn-sm slip-text-header text-light close-text" id="faso-close"
                    @click="showSuccessModal = false">
                    [x]
                  </span>
                </div>
              </div>
              <div class="col-4 p-0 m-0">
                <div style="float: right;" class="d-flex">
                  <span class="slip-text-header">
                  </span>
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
          <div style="display: flex; justify-content: center; align-items: center;">
            <img style="width: 150px;margin-top: 60px;" src="/img/check-icons.png" alt="Check">
          </div>
          <br />
          <h5 style="text-align: center;color: var(--darkBackground);">SUCCESS.</h5>
          <br />
          <br />
        </div>
      </div>
    </div>
    <section class="account-details">

      <div class="profile-info">
        <img src="/img/BottomNav/user-icon.png" class="menu-icon">
        <div>
          <template v-if="myProfile.first_name || myProfile.last_name">
            <strong>{{ myProfile.first_name }} {{ myProfile.last_name }}</strong>
          </template>
          <strong>{{ myProfile.msisdn }}</strong>
        </div>
      </div>
      <div class="wallet-info">
        <div>
          <div>
            <span class="player-profile-details s-a-1-txt">Bonus</span><br />
            <strong>{{ myBonusBalance }}</strong>
          </div>
        </div>
        <div class="account">
          <span class="s-a-1-txt">Balance</span><br />
          <strong>Ksh. <span>{{ myBalance }}</span></strong>
        </div>
      </div>



      <div class="wallet-actions">
        <button id="deposit-profile" :class="{ 'active': depositPage, 'loading': loading }"
          class="copy-betslip-btn1 form-control" data-toggle="modal" data-target="#walletDeposit">
          Deposit
        </button>
        <button id="withdrawal-profile" :class="{ 'active': withdrawalPage, 'loading': loading }"
          class="py-2 form-control withdraw" data-toggle="modal" data-target="#walletWithdraw">
          Withdraw
        </button>
      </div>

      <WalletDeposit />
      <WalletWithdraw />

      <div class="menu-items">
        <SortDeposit />
        <h4 class="section-title">
          Tucheze Extras
        </h4>
        <MenuItem to="/gifts">
        Promotions
        </MenuItem>
        <MenuItem to="/freebet">
        Freebet
        </MenuItem>
        <MenuItem to="/faq">
        F.A.Q
        </MenuItem>
        <MenuItem to="/print">
        Print Bets
        </MenuItem>

        <MenuItem to="/selfexclusion">
         Self Exclusion
        </MenuItem>


      </div>


    </section>
    <span id="open-shareinvite" class="hidden"></span>
  </div>
</template>

<script>
import walletserve from "@/services/walletserve";
import bonusserve from "@/services/bonus";
import SortDeposit from "./settings/SortDeposit.vue";
import MenuItem from "./settings/MenuItem.vue";
import WalletDeposit from './wallet/Deposit.vue';
import WalletWithdraw from './wallet/Withdraw.vue';

export default {
  name: "Setting",
  components: {
    SortDeposit,
    MenuItem,
    WalletDeposit,
    WalletWithdraw
  },
  data: function () {
    return {
      transaction_id: "",
      amount: "",
      mb8Balance: "0",
      loading: "",
      pro: {},
      bonus_code: "",
      errorDisplayed: false,
      withdraw_amount: "",
      new_referral_code: "",
      errors: [],
      code: "",
      link: "",
      copyText: "Copy",
      copyBookingCode: "Click to copy",
      myProfile: this.getProfile(),
      depositPage: true,
      withdrawalPage: false,
      myBonusBalance: 0,
      showSuccessModal: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = "My tucheze.com Account";
        document.description = "Manage tucheze.com account";
      },
    },
    new_referral_code: function (n) {
      console.log("got new value here " + n);

      this.validateReferralLink();
    },
  },
  created() {
    var profile = this.getProfile();

    // Check if the user is logged in
    var isLoggedIn = profile && profile.a;

    if (isLoggedIn) {
      this.EventBus.$on("mb8:updated", (mb8balance) => {
        this.receivedMB8Balance = mb8balance;
      });
      this.EventBus.$on("bonus:updated", (bonus) => {
        this.receivedBonus = bonus;
      });
    }
  },
  mounted() {
    this.fetchMyBonusBalance();
    this.$store.dispatch("setCurrentPage", "setting");
    var vm = this;

    vm.myProfile = vm.getProfile();
    // console.log("My Profile data", vm.myProfile);
    this.reloadProfile();

    // this.getBonus();

    if (!vm.myProfile) {
      this.setError("Login", "Please login to proceed");
      this.$router.push({ name: "login", params: {} });
      return;
    }

    setInterval(function () {
      vm.myProfile = vm.getProfile();
    }, 1000);

    this.code = vm.myProfile.referral_code;
    // this.link = vm.myProfile.referral_link;
    this.initShareInviteModal();
  },
  methods: {
    fetchMyBonusBalance() {
      var vm = this;

      const path = process.env.VUE_APP_BONUS_BASE_URL + "/bonus/balance";
      // console.log(this.getAuth())

      bonusserve.get(path, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': vm.getAuth()
        }
      })
        .then(response => {
          this.myBonusBalance = response.data.balance;
          console.log('Success bonus balance:', response.data.balance);
        })
        .catch(err => {
          console.error('Error bonus balance:', err);
        });
    },
    showDeposit() {
      this.depositPage = true;
      this.withdrawalPage = false;
    },
    showWithdrawal() {
      this.withdrawalPage = true;
      this.depositPage = false;
    },
    setAmount: function (amountToAdd) {
      // Convert the current amount to a number
      let currentAmount = parseFloat(this.amount);

      // Check if the current amount is a valid number
      if (isNaN(currentAmount)) {
        currentAmount = 0; // Set it to 0 if it's not a valid number
      }

      // Add the new amount to the existing amount
      currentAmount += amountToAdd;

      // Update the amount with the new total
      this.amount = currentAmount.toFixed(2); // Limit to 2 decimal places
    },

    generateOrRegenerateReferralCode: function () {
      this.reset();
      this.errors = [];

      var p = this.getProfile();
      var ref = p.referral_code;
      if (!p) {
        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: {} });
        return;
      }

      return ref;
    },

    redeemBonus: function () {
      var vm = this;
      var path = process.env.VUE_APP_BASE_BONUS_URL + "/campaign/redeem";

      var payload = {
        bonus_code: String(this.bonus_code),
      };

      bonusserve
        .post(path, JSON.stringify(payload), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          console.log("Response for Bonus", res);
          // var bonusdata = res.data.data;

          vm.bonusdata = res.data.data; 7
        })
        .catch((err) => {
          console.log("Error", err);
          vm.loading = "";
          if (err.response) {
            // console.log(JSON.stringify(err.request));
          }
          else {
            vm.setError(
              "Network Error",
              "Check your network connection and try again"
            );
            // console.log(JSON.stringify(err));
          }
        });
    },

    deposit: function () {
      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 10) {
        this.setError("Invalid Amount", "Enter amount atleast 10 KSH or above");
        return;
      }

      var vm = this;
      var path = "/deposit/initiate";

      var data = {
        amount: parseInt(this.amount),
      };

      vm.loading = "loading";
      console.log(this.getAuth());

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.data;
          console.log(msg);
          vm.setSuccess("Success", msg);
          this.showSuccessModal = true;
          setTimeout(() => {
            this.showSuccessModal = false;
          }, 2000);
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              vm.setError(
                "Session Expired",
                "Your session on this device has expired"
              );
              vm.logout();
              return;
            } else {
              vm.setError("Failed", err.response.data.error_message);
              // console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            vm.setError(
              "Network Error",
              "Check your network connection and try again"
            );
            // console.log(JSON.stringify(err.request));
          } else {
            // vm.setError(
            //   "Network Error",
            //   "Check your network connection and try again three"
            // );
            console.log(JSON.stringify(err));
          }
        });
    },

    jisort: function () {
      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: {} });
        return;
      }

      var vm = this;
      var path = "/deposit/reconcile";

      var data = {
        transaction_id: this.transaction_id,
      };

      vm.loading = "loading";
      console.log(this.getAuth());

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.data;
          console.log(msg);
          vm.setSuccess("Success", msg);
          this.showSuccessModal = true;
          setTimeout(() => {
            this.showSuccessModal = false;
          }, 2000);
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              vm.setError(
                "Session Expired",
                "Your session on this device has expired"
              );
              vm.logout();
              return;
            } else {
              vm.setError("Failed", err.response.data.error_message);
              // console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            vm.setError(
              "Network Error",
              "Check your network connection and try again"
            );
            // console.log(JSON.stringify(err.request));
          } else {
            // vm.setError(
            //   "Network Error",
            //   "Check your network connection and try again three"
            // );
            console.log(JSON.stringify(err));
          }
        });
    },

    withdraw: function () {

      if (this.loading === 'loading') {
        return;

      }

      var vm = this;

      setTimeout(function () {
        vm.loading = '';
      }, 5000);

      this.reset();

      var p = this.getAuth();

      if (!p) {

        this.loading = '';
        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.withdraw_amount < 50) {

        this.setError(
          "Invalid Amount",
          "Enter amount at least 50 KSH or above"
        );
        return;
      }

      var path = "/withdraw";

      var data = {
        amount: parseInt(this.withdraw_amount),
        // msisdn: parseInt(this.msisdn),
      };

      this.loading = 'loading';

      walletserve
        .post(path, data, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          console.log("Response for Withdraw", res)
          vm.loading = "";
          var msg = res.data.data;
          if (parseInt(res.data.status) === 200) {
            vm.setSuccess("Withdrawal Initiated", msg);
          }
          if (parseInt(res.data.status) === 201) {
            vm.setSuccess("Withdrawal Initiated", msg);
          }
          this.showSuccessModal = true;
          setTimeout(() => {
            this.showSuccessModal = false;
          }, 2000);
        })
        .catch((err) => {
          console.log("Error", err)
          vm.loading = "";
          var msg = err.response.data.error_message;
          if (!vm.errorDisplayed) {
            if (parseInt(err.response)) {
              vm.setError("Failed", msg);
            } else if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              vm.setError(
                "Session Expired",
                "Your session on this device has expired"
              );
              vm.logout();
            } else {
              vm.setError("Failed", msg);
              // console.log(JSON.stringify(err.response.data.message));
            }
            vm.errorDisplayed = true;
          }
        });
    },

    shareInvite: function () {
      document.getElementById("open-shareinvite").click();
    },
    copyShareBetLink: function () {
      var profile = this.getProfile();
      if (!profile) {
        return;
      }
      var link = "https://tucheze.com/join?p=" + profile.referral_code;
      this.copyToClipboard(link);
      this.copyText = "Copied";
    },

    copyCode: function () {
      var link = "accept#" + this.code;
      this.copyToClipboard(link);
      this.copyBookingCode = "Invite Code Copied";
    },

    initShareInviteModal: function () {
      var modal = document.getElementById("shareinvite-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("open-shareinvite");

      // Get the <span> element that closes the modal
      //var span = document.getElementsByClassName("sharebet-close")[0];

      // When the user clicks on the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
      };

      // When the user clicks on <span> (x), close the modal
      /*
      span.onclick = function() {
        modal.style.display = "none";
      }
      */

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };

      document.addEventListener("click", (e) => {
        if (e.target == document.querySelector("#shareinvite-modal")) {
          modal.style.display = "none";
        }
      });
    },
  },

  computed: {
    bal: function () {
      return this.formatCurrency(this.profile.b1);
    },
    bonus: function () {
      return this.formatCurrency(
        this.profile.balance + this.profile.pending_activation
      );
    },
    myBalance: function () {
      return this.formatCurrency(this.$store.state.balance);
    },
    myBonus: function () {
      return this.$store.state.bonus;
    },
    msg: function () {
      return "Karibu tucheze.com, Come let's win BIG together.";
    },
    profile: function () {
      return this.myProfile;
    },
    // has_referral_code: function () {
    //   return this.code !== undefined && this.code.length > 3;
    // },
    shouldDisplayTrivia() {
      const now = new Date();
      const startDate = new Date("2023-11-22T12:00:00");
      const endDate = new Date("2023-11-22T15:30:00");

      return now >= startDate && now <= endDate;
    },
  },
  filters: {
    currency: function (amount) {
      if (amount == null) {
        amount = 0;
      }

      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "KES",
      });
    },
    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }

      return parseFloat(x).toFixed(2);
    },
  },
};
</script>

<style scoped>
.account-details {
  background: var(--darkBackground);
  padding: 0.5rem 15px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-height: calc(100vh - 160px);
}

.account-details>.card {
  background: none;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--gray-white);

}

.list-active {
  background-color: #F08B05;
}

.title_name {
  color: #fff;
}

.parent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.parent .banner {
  width: 100%;
  /* height: 48px; */
}

.parent iframe {
  background: #000;
  border: none;
  flex-grow: 1;
}

h4 {
  position: relative;
  margin: 0;
}


.profile-info {
  background: var(--primary-bg-light);
  color: white;
  display: flex;
  border-radius: 0.5rem;
  padding: 1rem;
  align-items: center;
  gap: 0.5rem;
}

.profile-info .menu-icon {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  object-position: center;
  background: white;
  border-radius: 9999px;
  padding: 5px;
}

.profile-info>div {
  display: flex;
  gap: 0.1rem;
  flex-direction: column;
}

.wallet-info,
.wallet-actions {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

}

.wallet-info>div {
  flex: 1;
  background: var(--primary-bg-light);
  border-radius: 0.5rem;
  padding: 1rem;
}


.wallet-actions>button {
  background: var(--yellow);
  border: 2px transparent solid;
  color: var(--primary);
  padding: 1rem;
  text-align: center;
  border-radius: 0.5rem;
}


.wallet-actions>button:not(.active) {
  border-color: var(--yellow);
  background: transparent;
  color: var(--yellow);
}


.wallet-form .card {
  padding: 1rem;
}

.odd-btn {
  background: var(--light-gray);
  color: var(--primary);
  border-radius: 9999px;
  width: auto;
}

.wallet-btns-grid {
  gap: 0.5rem;
  flex-wrap: wrap;
  flex-direction: row;
  width: auto;
}

.form-wrapper1 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}


.menu-items {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1.5rem;
}

.section-title {
  margin: 0;
  font-size: 0.8rem;
  color: white;
  font-weight: normal;
}
</style>