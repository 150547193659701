<template>
    <div ref="walletModal" class="modal wallet-modal fade" :id="id" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <slot></slot>

            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "WalletModal",
    props: {
        id: {
            type: String,
            required: true
        },
    },
    mounted() {
        window.$(this.$refs.walletModal).on('hidden.bs.modal', this.onClose)
    },
    methods: {
        onClose() {
            this.$emit('close');
        },
        close() {
            window.$(this.$refs.walletModal).modal('hide');
        },
    }
}
</script>

<style scoped>
.wallet-modal {
    background: rgba(0, 0, 0, 0.8);
    padding: 1rem;
}

.modal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 400px;
}

.modal-content {
    background: var(--primary);
    border-radius: 0.5rem;
    padding: 1rem;
}
</style>