var render = function render(){var _vm=this,_c=_vm._self._c;return _c('WalletModal',{attrs:{"id":"walletDeposit"},on:{"close":_vm.onClose}},[_c('div',{staticClass:"wallet-form"},[_c('div',{staticClass:"text-light matches-title"},[_c('h4',[_vm._v("Deposit")]),_c('div',{staticClass:"balance"},[_vm._v(" Balance KES "+_vm._s(_vm.balance)+" ")])]),_c('div',{staticClass:"tabs-list"},[_c('ul',[_c('li',{class:{
                    'active': _vm.activeTab === 'deposit'
                },on:{"click":function($event){_vm.activeTab = 'deposit'}}},[_vm._v(" Deposit ")]),_c('li',{class:{
                    'active': _vm.activeTab === 'manual'
                },on:{"click":function($event){_vm.activeTab = 'manual'}}},[_vm._v(" Manual Deposit ")])])]),(_vm.activeTab === 'deposit')?_c('div',{staticClass:"form-wrapper1"},[(_vm.message)?_c('div',{staticClass:"alert",class:{
                'alert-success': _vm.message.type === 'success',
                'alert-danger': _vm.message.type === 'error'
            },attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.message.message)+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.amount),expression:"amount"}],staticClass:"form-control std-input text-center",attrs:{"type":"number","name":"amount","id":"deposit","placeholder":"10","min":"0","value":"99","aria-label":"Amount (to the nearest dollar)"},domProps:{"value":(_vm.amount)},on:{"input":function($event){if($event.target.composing)return;_vm.amount=$event.target.value}}}),_c('div',{staticClass:"d-flex wallet-btns-grid"},[_c('button',{staticClass:"btn odd-btn",on:{"click":function($event){return _vm.setAmount(20)}}},[_vm._v(" +"),_c('span',{staticClass:"deposit_val"},[_vm._v("20")])]),_c('button',{staticClass:"btn odd-btn",on:{"click":function($event){return _vm.setAmount(50)}}},[_vm._v(" +"),_c('span',{staticClass:"deposit_val"},[_vm._v("50")])]),_c('button',{staticClass:"btn odd-btn",on:{"click":function($event){return _vm.setAmount(100)}}},[_vm._v(" +"),_c('span',{staticClass:"deposit_val"},[_vm._v("100")])]),_c('button',{staticClass:"btn odd-btn",on:{"click":function($event){return _vm.setAmount(500)}}},[_vm._v(" +"),_c('span',{staticClass:"deposit_val"},[_vm._v("500")])])]),_c('button',{staticClass:"btn-std btn-sec btn-large",class:_vm.loading,attrs:{"id":"deposit-profile","disabled":_vm.loading},on:{"click":_vm.deposit}},[_vm._v(" "+_vm._s(_vm.loading ? 'Processing...' : 'Deposit')+" ")])]):_c('ManualDeposit',{attrs:{"profile":_vm.profile}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }