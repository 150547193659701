<template>
    <WalletModal id="walletDeposit" @close="onClose">
        <div class="wallet-form">
            <div class="text-light matches-title">
                <h4>Deposit</h4>
                <div class="balance">
                    Balance KES {{ balance }}
                </div>
            </div>
            
            <div class="tabs-list">
                <ul>
                    <li @click="activeTab = 'deposit'" :class="{
                        'active': activeTab === 'deposit'
                    }">
                        Deposit
                    </li>
                    <li @click="activeTab = 'manual'" :class="{
                        'active': activeTab === 'manual'
                    }">
                        Manual Deposit
                    </li>
                </ul>
            </div>

            <div class="form-wrapper1" v-if="activeTab === 'deposit'">
                <div v-if="message" class="alert" v-bind:class="{
                    'alert-success': message.type === 'success',
                    'alert-danger': message.type === 'error'
                }" role="alert">
                    {{ message.message }}
                </div>
                <input type="number" name="amount" id="deposit" placeholder="10" min="0"
                    class="form-control std-input text-center" value="99" aria-label="Amount (to the nearest dollar)"
                    v-model="amount" />
                <div class="d-flex wallet-btns-grid">
                    <button class="btn odd-btn" @click="setAmount(20)">
                        +<span class="deposit_val">20</span>
                    </button>
                    <button class="btn odd-btn" @click="setAmount(50)">
                        +<span class="deposit_val">50</span>
                    </button>
                    <button class="btn odd-btn" @click="setAmount(100)">
                        +<span class="deposit_val">100</span>
                    </button>
                    <button class="btn odd-btn" @click="setAmount(500)">
                        +<span class="deposit_val">500</span>
                    </button>
                </div>

                <button @click="deposit" id="deposit-profile" v-bind:class="loading" class="btn-std btn-sec btn-large"
                    :disabled="loading">
                    {{ loading ? 'Processing...' : 'Deposit' }}

                </button>
            </div>
            <ManualDeposit :profile="profile" v-else />
        </div>
    </WalletModal>
</template>

<script>
import WalletModal from './Modal.vue';
import walletserve from "@/services/walletserve";
import { pushToDataLayer } from '@/utils/gtm';
import ManualDeposit from './ManualDeposit.vue';

export default {
    name: 'WalletDeposit',
    data: function () {
        return {
            loading: false,
            amount: null,
            message: null,
            activeTab: 'deposit',
            profile: this.getProfile()
        }
    },
    computed: {
        balance() {
            return this.formatCurrency(this.profile?.b1);
        }
    },  
    components: {
        WalletModal,
        ManualDeposit
    },
    methods: {
        onClose() {
            this.amount = null;
            this.message = null;
            this.loading = false;
        },
        setAmount: function (amountToAdd) {
            let currentAmount = parseFloat(this.amount);

            if (isNaN(currentAmount)) {
                currentAmount = 0;
            }

            currentAmount += amountToAdd;

            this.amount = currentAmount.toFixed(2); // Limit to 2 decimal places
        },
        handleDepositClick(event) {
            this.deposit(event);
        },
        trackDepositButtonClick(event) {
            pushToDataLayer('gtm.deposit', {
                category: 'Button',
                action: 'Click',
                label: 'Deposit',
                element: event.target
            });
        },
        deposit: function (event) {
            this.loading = false
            this.message = null
            var p = this.getAuth();
            if (!p) {
                this.setError("Login", "Please login to proceed");
                this.$router.push({ name: "login", params: {} });
                return;
            }

            if (this.amount < 10) {
                this.message = {
                    type: "error",
                    message: "Invalid Amount. Enter amount atleast 10 KSH or above"
                };
                return;
            }

            var vm = this;
            var path = "/deposit/initiate";

            var data = {
                amount: parseInt(this.amount),
            };

            this.loading = true;

            walletserve
                .post(path, JSON.stringify(data), {
                    headers: {
                        "api-key": vm.getAuth(),
                    },
                })
                .then((res) => {
                    this.trackDepositButtonClick(event);
                    var msg = res.data.data;
                    this.message = {
                        message: msg,
                        type: 'success'
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (
                            parseInt(err.response.data.error_code) === 401 ||
                            parseInt(err.response.data.error_code) === 400 ||
                            parseInt(err.response.data.error_code) === 428
                        ) {
                            vm.setError(
                                "Session Expired",
                                "Your session on this device has expired"
                            );
                            vm.logout();
                            return;
                        } else {
                            this.message = {
                                message: err.response.data.error_message ?? 'Failed',
                                type: 'error'
                            }
                        }
                    } else if (err.request) {
                        this.message = {
                            message: "Check your network connection and try again",
                            type: 'error'
                        }
                    } else {
                        this.message = {
                            message: "An error occurred. Please try again",
                            type: 'error'
                        }
                        console.log(JSON.stringify(err));
                    }
                }).finally(() => {
                    this.loading = false
                });
        },
    }

}
</script>

<style scoped>
.wallet-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
}

.form-wrapper1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-wrapper1 input {
    background: var(--primary-bg-light);
    color: white;
}

.alert {
    position: relative;
    border-radius: 0.5rem;

}

h4 {
    margin: 0;
    margin-bottom: 0.5rem;
}

.wallet-btns-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.wallet-btns-grid .odd-btn {
    padding: 0.2rem;
    font-size: 0.8rem;
    background: var(--primary-bg-light);
    color: white;
    border: none;
    border-radius: 0.5rem;
    width: auto;
}

.tabs-list {
    width: 100%;
}

.tabs-list ul {
    display: flex;
    justify-content: center;
    list-style: none;
    justify-content: flex-start;
    padding: 0;
    gap: 1rem;
    border-bottom: 1px var(--primary-bg-light) solid;
}

.tabs-list ul li {
    cursor: pointer;
    border-bottom: 1px solid transparent;
    padding: 10px 0;
    margin-bottom: -1px;
}

.tabs-list ul li.active {
    border-color: var(--yellow);
    color: var(--yellow);
    font-weight: bold;
}

.balance {
    background: #000C15;
    color: var(--yellow);
    border-radius: 0.3rem;
    padding: 0.2rem 0.5rem;
    display: table;
    font-size: 0.8rem;
}

</style>